@font-face {
  font-family: "DM Sans";
  font-display: swap;
  src: local("DM_Sans"), url("./Fonts/DefaultFonts/DM_Sans/DMSans-VariableFont_opsz,wght.ttf") format("truetype");
}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html {
  min-height: 100vh;
}

body {
  width: 100vw;
  height: 100vh;
  background: #f9f9f9;
}

#root {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background: #0d0c0c; */
}

.active-production {
  background-color: #f9f9f9;
}

.custom-bullet {
  border-radius: 0;
  width: 30px !important;
  height: 5px !important;
}

.swiper-pagination {
  background-color: #0d0c0c;
  bottom: 0 !important;
  padding-top: 10px;
}

.swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  background: #f9f9f9;
  margin: 0 13px !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: #f9f9f9 !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #c9c9c9 !important;
}

.react-loading {
  margin: 0 auto;
  animation: visible 1s linear forwards;
}

@keyframes text-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  10% {
    opacity: 0.5;
  }

  20% {
    opacity: 1;
    transform: translateY(0px);
  }

  30% {
    opacity: 1;
    transform: translateY(0px);
  }

  40% {
    opacity: 1;
    transform: translateY(0px);
  }

  50% {
    opacity: 1;
    transform: translateY(0px);
  }

  60% {
    opacity: 1;
    transform: translateY(0px);
  }

  70% {
    opacity: 1;
    transform: translateY(0px);
  }

  80% {
    opacity: 1;
    transform: translateY(0px);
  }

  90% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(40px);
  }
}

.start .opening-animation {
  display: flex;
}

.text-1,
.text-3,
.text-5,
.text-7,
.text-9,
.text-11 {
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px #f9f9f9;
}

@keyframes visible {
  0% {
    opacity: 1;
  }

  98% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-out-container {
  0% {
    height: 100vh;
  }

  50% {
    height: 100vh;
  }

  100% {
    height: 0%;
  }
}

@keyframes blink {
  0% {
    opacity: 0%;
  }

  1% {
    opacity: 100%;
  }

  99% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}

.text-1 {
  animation: blink 0.8s linear 0.4s;
  opacity: 0;
}

.text-2 {
  animation: blink 0.8s linear 0.5s;
  opacity: 0;
}

.text-3 {
  animation: blink 0.8s linear 0.6s;
  opacity: 0;
}

.text-4 {
  animation: blink 0.8s linear 0.7s;
  opacity: 0;
}

.text-5 {
  animation: blink 0.8s linear 0.8s;
  opacity: 0;
}

.text-6 {
  animation: blink 0.8s linear 0.9s, visible 1s linear 1.5s 1 normal;
  opacity: 0;
}

.text-7 {
  animation: blink 0.8s linear 0.8s;
  opacity: 0;
}

.text-8 {
  animation: blink 0.8s linear 0.7s;
  opacity: 0;
}

.text-9 {
  animation: blink 0.8s linear 0.6s;
  opacity: 0;
}

.text-10 {
  animation: blink 0.8s linear 0.5s;
  opacity: 0;
}

.text-11 {
  animation: blink 0.8s linear 0.4s;
  opacity: 0;
}

.border-0 {
  border: none;
}

.active span {
  font-weight: 500 !important;
}

.active span:nth-of-type(1) {
  transform: skewX(-15deg);
  font-weight: 700 !important;
}

a:nth-of-type(1).active span:last-child {
  margin-left: -0.5px;
}

a:not(:nth-of-type(1)).active span:last-child,
li:not(:nth-of-type(1)) a.active span:last-child {
  margin-left: 1.55px;
}

a {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

.display-on-mobile {
  display: block;
}

@media only screen and (max-width: 600px) {
  .display-on-mobile {
    display: none;
  }

  .display-on-desktop {
    display: block;
  }
}

/*# sourceMappingURL=styles.css.map */